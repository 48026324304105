import axios from "axios";

export const getUserAgentInfo = async () => {
  return {
    userAgent: navigator.userAgent,
    platform: navigator.platform,
  };
};

export const getLocationInfo = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const {
      ip,
      network,
      city,
      region,
      country_name,
      latitude,
      longitude,
      timezone,
      asn,
      org,
    } = response.data;
    return {
      ip,
      network,
      city,
      region,
      country_name,
      latitude,
      longitude,
      timezone,
      asn,
      org,
    };
  } catch (error) {
    console.error("Error fetching location info:", error);
    throw error;
  }
};

export const postInfo = async data => {
  try {
    const response = await axios.post(
      "https://adsleadformbackend2024-265179041302.us-central1.run.app/api/puap/leadformEmail", 
       data
    );
    return response.data.token;
  } catch (error) {
    console.error("Error in post:", error);
    throw error;
  }
};
