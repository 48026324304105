export const puapModels = {
    Acura: [
      "CL",
      "CSX",
      "EL",
      "ILX",
      "Integra",
      "Legend",
      "MDX",
      "NSX",
      "RDX",
      "RL",
      "RLX",
      "RSX",
      "SLX",
      "TL",
      "TLX",
      "TSX",
      "Vigor",
      "ZDX",
    ],
    "Alfa Romeo": [
      "147",
      "164",
      "1750",
      "4C",
      "8C Competizione Spider",
      "Alfetta",
      "Giulia (952)",
      "Giulia 1600",
      "Giulietta",
      "GTV6",
      "Milano",
      "Mito",
      "Spider-1600 Duetto",
      "Spider-1600",
      "Spider-2000",
      "Spider",
      "Stelvio",
      "TONALE",
    ],
    "Aston Martin": [
      "DB11",
      "DB12",
      "DB9",
      "DBS",
      "DBX",
      "Lagonda",
      "Rapide",
      "V12 Vantage",
      "V8",
      "V8 Vantage",
      "VALOUR",
      "Vanquish",
      "Vanquish Zagato",
      "Vantage",
      "Virage",
    ],
    Audi: [
      "80 series",
      "90 series",
      "100",
      "200",
      "4000",
      "5000",
      "A3",
      "A4",
      "A4 Allroad",
      "A5",
      "A6",
      "A6 Allroad",
      "A7",
      "A7 e",
      "A8",
      "A8 e",
      "A8 L",
      "A8 L e",
      "Allroad",
      "Cabriolet",
      "Coupe GT",
      "e-tron",
      "e-tron GT",
      "e-tron Sportback",
      "Q3",
      "Q4 Etron",
      "Q5",
      "Q5 e",
      "Q7",
      "Q8",
      "R8",
      "RS 3",
      "RS 4",
      "RS 5",
      "RS 6",
      "RS 7",
      "RS e-tron GT",
      "RS Q8",
      "S3",
      "S4",
      "S5",
      "S6",
      "S7",
      "S8",
      "SQ5",
      "SQ7",
      "SQ8",
      "TT",
      "TT RS",
      "TTS",
      "V8 Quattro",
    ],
    Bentley: [
      "Armoured Arnage",
      "Arnage",
      "Azure",
      "Bentayga",
      "Brooklands",
      "Continental",
      "Eight",
      "Flying Spur",
      "Mulsanne",
      "Roll Royce Silver Seraph",
      "Rolls-Royce Park Ward",
      "Turbo",
    ],
    BMW: [
      "335",
      "128i",
      "135i",
      "1M",
      "228i",
      "230i",
      "318i",
      "318iC",
      "318iS",
      "318ti",
      "320i",
      "323i",
      "323iC",
      "323is",
      "325 / 325e",
      "325 / 325eS",
      "325Ci",
      "325i",
      "325i / 325is",
      "325iC",
      "325iS",
      "325ix",
      "325xi",
      "328Ci",
      "328d",
      "328i",
      "328iC",
      "328iS",
      "328xi",
      "330Ci",
      "330e",
      "330i",
      "330xi",
      "335d",
      "335i",
      "335is",
      "335xi",
      "340i",
      "428i",
      "430i",
      "435i",
      "440i",
      "524td",
      "525i",
      "525iA",
      "525xi",
      "528e",
      "528i",
      "528xi",
      "530e",
      "530i",
      "530iA",
      "530xi",
      "533i",
      "535d",
      "535i",
      "535i / 535is",
      "535xi",
      "540d",
      "540i",
      "540iA",
      "545i",
      "550i",
      "633 csi",
      "635CSi",
      "640i",
      "640xi",
      "645Ci",
      "645i",
      "650i",
      "650i / Alpina B6",
      "650i / B6",
      "650xi",
      "730i",
      "733i",
      "735i",
      "735iL",
      "740e",
      "740i",
      "740iL",
      "740Li",
      "745e",
      "745i",
      "745Le",
      "745Li",
      "750e",
      "750i",
      "750i / Alpina B7",
      "750i / B7",
      "750iL",
      "750Li",
      "750Li / Alpina B7",
      "750Lxi",
      "750Lxi / Alpina B7",
      "750xi",
      "750xi / Alpina B7",
      "760i",
      "760Li",
      "840Ci",
      "840i",
      "850Ci",
      "850CSi",
      "850i",
      "Active E",
      "Active Hybrid 3",
      "Active Hybrid 5",
      "Active Hybrid 7",
      "Alpina",
      "Alpina B8",
      "Alpina B7",
      "i3",
      "i4",
      "i5",
      "i7",
      "i8",
      "iX",
      "L7",
      "M2",
      "M235i",
      "M240i",
      "M3",
      "M340i",
      "M3Ci",
      "M4",
      "M440i",
      "M5",
      "M550i",
      "M6",
      "M760i",
      "M760Li",
      "M8",
      "M850i",
      "X1",
      "X2",
      "X3",
      "X4",
      "X5",
      "X6",
      "X7",
      "XM",
      "Z3",
      "Z4",
      "Z8",
    ],
    Buick: [
      "Allure",
      "Cascada",
      "Century",
      "Coachbuilder",
      "Electra",
      "Enclave",
      "Encore",
      "Encore GX",
      "Envision",
      "Envista",
      "LaCrosse",
      "LeSabre",
      "Lucerne",
      "Park Avenue",
      "Rainier",
      "Reatta",
      "Regal",
      "Regal TourX",
      "Rendezvous",
      "Riviera",
      "Roadmaster",
      "Skyhawk",
      "Skylark",
      "Somerset",
      "Terraza",
      "Verano",
    ],
    Cadillac: [
      "60 Special",
      "Allante",
      "Armored Vehicle",
      "ATS",
      "Brougham",
      "Catera",
      "Celestiq",
      "Cimarron",
      "Commercial Chassis",
      "CT4",
      "CT5",
      "CT6",
      "CTS",
      "Deville",
      "DTS",
      "Eldorado",
      "ELR",
      "Escalade",
      "Escalade ESV",
      "Escalade IQ",
      "Fleetwood",
      "Funeral Coach",
      "Hearse",
      "Limousine",
      "Lyriq",
      "Seville",
      "SRX",
      "Stretch Limousine",
      "STS",
      "XLR",
      "XT4",
      "XT5",
      "XT6",
      "XTS",
    ],
    Chevrolet: [
      "3500",
      "4500",
      "3 ton",
      "3500 / 4500",
      "3500HD",
      "3500HG",
      "4500HD",
      "4500HG",
      "4500XD",
      "5500HD",
      "5500HG",
      "5500XD",
      "5500XG",
      "6500XD",
      "7500XD",
      "Alero",
      "Aluminum Tilt",
      "Astro Van",
      "Avalanche",
      "Aveo",
      "Beretta",
      "Blazer",
      "Blazer EV",
      "Bolt EUV",
      "Bolt EV",
      "Bolt Incomplete",
      "Bus Chassis",
      "C/K Pickup",
      "C4",
      "C5",
      "C6",
      "C7",
      "C8",
      "Camaro",
      "Caprice",
      "Caprice Police Vehicle",
      "Captiva Sport",
      "Cavalier",
      "Celebrity",
      "Chevette",
      "Citation",
      "City Express",
      "Cobalt",
      "Colorado",
      "Corsica",
      "Corvette",
      "Cruze",
      "Cruze Limited",
      "Cutaway Chassis",
      "Cutaway Van",
      "D7",
      "El Camino",
      "Epica",
      "Equinox",
      "Equinox EV",
      "Express",
      "Forward Control",
      "FTR / 6500XD",
      "G-Series",
      "Geo Prizm",
      "Geo Spectrum",
      "Geo Sprint",
      "GM51P",
      "GMT-400",
      "Hearse/Limo",
      "Hearse/Limo Commercial Chassis",
      "Heavy Conventional",
      "HHR",
      "Hi-Cube",
      "Impala",
      "Impala Limited",
      "Kalos",
      "Lumina",
      "Lumina APV",
      "LUV",
      "Malibu",
      "Malibu Classic",
      "Malibu Limited",
      "Matiz",
      "Metro",
      "Military Truck",
      "Monte Carlo",
      "Motorhome Chassis",
      "Nova",
      "Onix",
      "Optra",
      "Orlando",
      "P - Series",
      "P Truck Forward",
      "P6S",
      "R Conventional",
      "S-10 Blazer",
      "S-10 Pickup",
      "S6",
      "S7",
      "Silverado",
      "Silverado EV",
      "Silverado HD",
      "Silverado LD",
      "Silverado LTD",
      "Sonic",
      "Spark",
      "SS",
      "SSR",
      "Suburban",
      "T-Series",
      "Tahoe",
      "Tracker",
      "Trailblazer",
      "Traverse",
      "Traverse Limited",
      "Trax",
      "U100",
      "Uplander",
      "V Conventional",
      "Venture",
      "Volt",
      "W3",
      "W3500/W4500",
      "W4",
      "W5",
      "W5500/W5500 HD",
      "W6",
      "W7",
    ],
    Chrysler: [
      "200",
      "300",
      "300C",
      "Aspen",
      "Caravan",
      "Cirrus",
      "Concorde",
      "Concorde/LHS",
      "Conquest",
      "Cordoba",
      "Crossfire",
      "Daytona",
      "Dynasty",
      "E-CLASS",
      "EXECUTIVE",
      "FIFTH AVENUE",
      "Grand Caravan",
      "Grand Voyager",
      "Imperial",
      "Intrepid",
      "LASER",
      "LeBaron",
      "LHS",
      "Neon",
      "New Yorker",
      "NEWPORT",
      "Pacifica",
      "Prowler",
      "PT Cruiser",
      "SALON",
      "Sebring",
      "Shadow",
      "Stratus",
      "TC",
      "Town and Country",
      "Viper",
      "Vision",
      "Voyager",
    ],
    Dodge: [
      "400",
      "600",
      "ARIES",
      "Avenger",
      "Caliber",
      "Caravan",
      "Caravan/Grand Caravan",
      "Challenger",
      "Charger",
      "Chassis with Powertrain",
      "Colt",
      "Conquest",
      "D-Series",
      "D50",
      "Dakota",
      "Dart",
      "Daytona",
      "DIPLOMAT",
      "Durango",
      "Dynasty",
      "Grand Caravan",
      "HORNET",
      "Intrepid",
      "Journey",
      "LANCER",
      "Magnum",
      "Mini Ram",
      "Mirada",
      "Monaco",
      "Neon",
      "Nitro",
      "OMNI",
      "Raider",
      "Ram",
      "RAM 50",
      "Ram Chassis Cab",
      "Ram Van",
      "Ram Wagon",
      "Ramcharger",
      "Rampage",
      "RD200 / RD250",
      "Royal Mini Ram Van",
      "Shadow",
      "Shelby Charger",
      "Spirit",
      "Sprinter",
      "ST.REGIS",
      "Stealth",
      "Stratus",
      "Viper",
      "W-Series",
    ],
    Ferrari: [
      "328",
      "12Cilindri",
      "296 GTB",
      "296 GTS",
      "3.2 Mondial",
      "308 Convertible",
      "308GTB",
      "308GTB Quattrovalvole",
      "308GTBi",
      "308GTS",
      "308GTS Quattrovalvole",
      "308GTSi",
      "328 GTB",
      "328 GTS",
      "348 Spider",
      "348 tb",
      "348 ts",
      "355 Berlinetta",
      "355 GTS",
      "355 Spider",
      "360 Modena",
      "360 Spider",
      "430 Scuderia",
      "430 Scuderia Spider",
      "456 GT",
      "456 MGT",
      "456 MGTA",
      "458 Italia",
      "458 Speciale",
      "458 Speciale Spider",
      "458 Spider",
      "488 GTB",
      "488 GTS",
      "488 Pista",
      "488 Pista Spider",
      "488 Spider",
      "512 TR",
      "550 Barchetta",
      "550 Maranello",
      "575 M Maranello",
      "599 GTB",
      "599 GTB Fiorano",
      "599 GTO",
      "612 Scaglietti",
      "812 Competizione",
      "812 Competizione A",
      "812 Superfast",
      "812GTS",
      "California",
      "California T",
      "Challenge Stradale",
      "Daytona SP3",
      "Enzo",
      "F12 Berlinetta",
      "F12 Special Series",
      "F12 tdf (Tour de France)",
      "F164BCB",
      "F355",
      "F40",
      "F430",
      "F430 Coupe",
      "F430 Spider",
      "F50",
      "F60 America",
      "F8 Spider",
      "F8 Tributo",
      "FF",
      "GTC4Lusso",
      "La Ferrari",
      "Mondial 8",
      "Mondial T",
      "Monza SP1/SP2",
      "Portofino",
      "Portofino M",
      "Purosangue",
      "Roma",
      "Roma Spider",
      "SF90 Spider",
      "SF90 Stradale",
      "SF90 XX Spider",
      "SF90 XX Stradale",
      "Testarossa",
    ],
    Fiat: [
      "500",
      "124 Spider",
      "500e",
      "500L",
      "500X",
      "Brava",
      "Ducato",
      "Freemont",
      "Spider 2000",
      "Strada",
      "X 1/9",
    ],
    Ford: [
      "34",
      "A8513",
      "A9513",
      "A9522",
      "Aerostar",
      "Aspire",
      "AT8513",
      "AT9513",
      "AT9522",
      "B-750",
      "B600",
      "B6000",
      "B700",
      "B7000",
      "B800",
      "Bronco",
      "Bronco II",
      "Bronco Sport",
      "C-Max",
      "C600",
      "C700",
      "C7000",
      "C800",
      "C8000",
      "CF6000",
      "CF7000",
      "CF8000",
      "CFT8000",
      "CL9000",
      "CLT9000",
      "Commercial Chassis",
      "Contour",
      "Courier",
      "Crown Victoria",
      "CT800",
      "CT8000",
      "E-100",
      "E-150",
      "E-250",
      "E-350",
      "E-450",
      "E-550",
      "Ecosport",
      "Edge",
      "Escape",
      "Escort",
      "Excursion",
      "Expedition",
      "Expedition EL",
      "Expedition MAX",
      "Explorer",
      "Explorer Sport",
      "Explorer Sport Trac",
      "F-100",
      "F-150",
      "F-150 Heritage",
      "F-250",
      "F-350",
      "F-450",
      "F-550",
      "F-590",
      "F-600",
      "F-650",
      "F-700",
      "F-750",
      "F-800",
      "F-Super Duty",
      "F6000",
      "F7000",
      "F8000",
      "Fairmont",
      "Festiva",
      "Fiesta",
      "Five Hundred",
      "Flex",
      "Focus",
      "Freestar",
      "Freestyle",
      "FT800",
      "FT8000",
      "FT900",
      "Fusion",
      "Granada",
      "GT",
      "GT MKII",
      "L800",
      "L8000",
      "L8501",
      "L8511",
      "L8513",
      "L9000",
      "L9501",
      "L9511",
      "L9513",
      "L9522",
      "LA8000",
      "LA9000",
      "Laser",
      "LL9000",
      "LLA9000",
      "LLS9000",
      "LN600",
      "LN700",
      "LN7000",
      "LN800",
      "LN8000",
      "LN9000",
      "LNT800",
      "LNT8000",
      "LNT9000",
      "Low Cab Forward",
      "LS8000",
      "LS9000",
      "LT800",
      "LT8000",
      "LT8501",
      "LT8511",
      "LT8513",
      "LT9000",
      "LT9501",
      "LT9511",
      "LT9513",
      "LT9522",
      "LTA9000",
      "LTD",
      "LTL9000",
      "LTLA9000",
      "LTLS9000",
      "LTS8000",
      "LTS9000",
      "Maverick",
      "Motorhome Chassis",
      "Mustang",
      "Mustang Mach-E",
      "P600",
      "P700",
      "P800",
      "Probe",
      "Ranger",
      "Recreational Vehicle",
      "Taurus",
      "Taurus X",
      "Tempo",
      "Thunderbird",
      "Transit",
      "Transit Connect",
      "Windstar",
      "ZX2",
    ],
    Genesis: [
      "Electrified G80",
      "Electrified GV70",
      "G70",
      "G80",
      "G90",
      "GV60",
      "GV70",
      "GV80",
    ],
    Geo: ["Metro", "Prizm", "Spectrum", "Sprint", "Storm", "Tracker"],
    GMC: [
      "Acadia",
      "Aluminum Tilt",
      "B7",
      "Bus Chassis",
      "C/K Pickup",
      "C4",
      "C5",
      "C6",
      "C7",
      "C8",
      "Caballero",
      "Canyon",
      "Cruise Origin AV",
      "Cutaway Chassis",
      "Cutaway Van",
      "D7",
      "Electric Vehicle",
      "Envoy",
      "Geo Tracker",
      "GMT-400",
      "Heavy Conventional",
      "Hi-Cube",
      "Hummer EV Pickup",
      "Hummer EV SUV",
      "Jimmy",
      "Jimmy Utility",
      "Magna Van",
      "Motorhome Chassis",
      "P Truck Forward",
      "P6S",
      "R Conventional",
      "Rally",
      "S15 Pickup",
      "S15 Utility",
      "S6",
      "S7",
      "Safari Van",
      "Savana",
      "Sierra",
      "Sierra EV",
      "Sierra HD",
      "Sierra Limited",
      "Silverado Medium Duty (GM515)",
      "Silverado Medium Duty (GM51P)",
      "Sonoma",
      "Suburban",
      "T-Series",
      "Terrain",
      "Transit Coach",
      "Typhoon",
      "V Conventional",
      "Value Van",
      "Vandura",
      "W3",
      "W3500/W4500",
      "W4",
      "W5",
      "W5500/W5500 HD",
      "W6",
      "W7",
      "Yukon",
      "Yukon XL",
    ],
    Honda: [
      "Accord",
      "Accord Crosstour",
      "Civic",
      "CIVIC SI",
      "Civic Type R",
      "Clarity",
      "CR-V",
      "CR-Z",
      "Crosstour",
      "del Sol",
      "Element",
      "EV Plus",
      "FCX Clarity",
      "Fit",
      "Honda Utility Vehicle",
      "HR-V",
      "Insight",
      "Odyssey",
      "Passport",
      "Pilot",
      "Prelude",
      "Prologue",
      "Ridgeline",
      "S2000",
    ],
    Hummer: ["H1", "H2", "H3", "H3T"],
    Hyundai: [
      "H1",
      "H2",
      "H3",
      "H3T",
      "Accent",
      "Azera",
      "Elantra",
      "Elantra GT",
      "Elantra N",
      "Elantra Touring",
      "Entourage",
      "Equus",
      "Excel",
      "Genesis",
      "Genesis Coupe",
      "Ioniq",
      "Ioniq 5",
      "IONIQ 5 N",
      "Ioniq 6",
      "Kona",
      "Kona Electric",
      "Kona N",
      "Nexo",
      "Palisade",
      "Pony",
      "Santa Cruz",
      "Santa Fe",
      "Santa Fe Sport",
      "Santa Fe XL",
      "Scoupe",
      "Sonata",
      "Stellar",
      "Tiburon",
      "Tucson",
      "Veloster",
      "Veloster N",
      "Venue",
      "Veracruz",
      "Xcient",
      "XG300",
      "XG350",
    ],
    Infiniti: [
      "EX35",
      "FX35",
      "FX37",
      "FX45",
      "FX50",
      "G20",
      "G25",
      "G35",
      "G37",
      "I30",
      "I35",
      "J30",
      "JX35",
      "M30",
      "M35",
      "M35h",
      "M37",
      "M45",
      "M56",
      "Q40",
      "Q45",
      "Q50",
      "Q60",
      "Q70",
      "Q70L",
      "QX30",
      "QX4",
      "QX50",
      "QX55",
      "QX56",
      "QX60",
      "QX70",
      "QX80",
    ],
    Isuzu: [
      "4000 Series Medium Duty",
      "5000 Series Medium Duty",
      "6000 Series Medium Duty",
      "7000 Series Medium Duty",
      "Amigo",
      "Ascender",
      "Axiom",
      "F6",
      "F7",
      "Forward Control",
      "Forward Control (for walk-in van)",
      "FRR",
      "FSR",
      "FTR",
      "FTR/FVR",
      "FTR/FVR/EVR",
      "FVR",
      "H-Series",
      "Hombre",
      "i-280",
      "i-290",
      "i-350",
      "i-370",
      "I-Mark",
      "IMPULSE",
      "NPR",
      "NPR-HD",
      "NPR-XD",
      "NPR/NPR-HD",
      "NQR",
      "NQR/NRR",
      "NRR",
      "Oasis",
      "Pickup",
      "REACH",
      "Rodeo",
      "Rodeo/Amigo",
      "Stylus",
      "T6F",
      "T7F",
      "T8F",
      "Trooper",
      "Trooper II",
      "VehiCross",
    ],
    Jaguar: [
      "E-PACE",
      "F-PACE",
      "F-TYPE",
      "I-PACE",
      "S-Type",
      "Vanden Plas",
      "X-Type",
      "XE",
      "XF",
      "XJ",
      "XJ12",
      "XJ6",
      "XJ8",
      "XJR",
      "XJS",
      "XK",
      "XK8",
    ],
    Jeep: [
      "Cherokee",
      "CJ-5",
      "CJ-6",
      "CJ-7",
      "CJ-8 Scrambler",
      "Comanche",
      "Commander",
      "Compass",
      "Gladiator",
      "Grand Cherokee",
      "Grand Cherokee L",
      "Grand Wagoneer",
      "Grand Wagoneer L",
      "J-10",
      "J-20",
      "Liberty",
      "Patriot",
      "Renegade",
      "Wagoneer",
      "Wagoneer L",
      "Wagoneer S",
      "Wrangler",
      "Wrangler JK",
    ],
    KIA: [
      "Amanti",
      "Borrego",
      "Cadenza",
      "Carnival",
      "EV6",
      "EV9",
      "Forte",
      "Forte Koup",
      "K5",
      "K900",
      "Niro",
      "Optima",
      "Rio",
      "Rondo",
      "Sedona",
      "Seltos",
      "Sephia",
      "Sephia II/Spectra",
      "Sorento",
      "Soul",
      "Soul EV",
      "Spectra",
      "Sportage",
      "Stinger",
      "Telluride",
    ],
    Lamborghini: [
      "147",
      "Aventador",
      "Diablo",
      "Gallardo",
      "Huracan",
      "Huracan STO",
      "Murcielago",
      "Revuelto",
      "Roadster",
      "URUS",
    ],
    "Land Rover": [
      "Defender",
      "Discovery",
      "Discovery Sport",
      "Freelander",
      "LR2",
      "LR3",
      "LR4",
      "New Range Rover",
      "Range Rover",
      "Range Rover Evoque",
      "Range Rover Sport",
      "Range Rover Velar",
    ],
    Lexus: [
      "CT",
      "ES",
      "GS",
      "GX",
      "HS",
      "IS",
      "LC",
      "LFA",
      "LS",
      "LX",
      "NX",
      "RC",
      "RC F",
      "RX",
      "RZ",
      "SC",
      "TX",
      "UX",
    ],
    Lincoln: [
      "Aviator",
      "Blackwood",
      "Continental",
      "Corsair",
      "LS",
      "Mark",
      "Mark LT",
      "MKC",
      "MKS",
      "MKT",
      "MKX",
      "MKZ",
      "Nautilus",
      "Navigator",
      "Navigator L",
      "Town Car",
      "Zephyr",
    ],
    Maserati: [
      "228",
      "430",
      "Biturbo",
      "Coupe",
      "Ghibli",
      "Granturismo",
      "GRANTURISMO / GRANCABRIO",
      "Grecale",
      "Levante",
      "MC20",
      "Merak",
      "Quattroporte",
      "Spyder",
      "TC",
    ],
    Mazda: [
      "323",
      "626",
      "929",
      "B-Series",
      "CX-3",
      "CX-30",
      "CX-5",
      "CX-50",
      "CX-7",
      "CX-70",
      "CX-9",
      "CX-90",
      "GLC",
      "Mazda2",
      "Mazda3",
      "Mazda5",
      "Mazda6",
      "Millenia",
      "MPV",
      "MX-3",
      "MX-30",
      "MX-5",
      "MX-6",
      "Navajo",
      "Protege",
      "RX-7",
      "RX-8",
      "Tribute",
    ],
    McLaren: [
      "540C",
      "570GT",
      "570S",
      "600LT",
      "620R",
      "625C",
      "650S",
      "675LT",
      "720S",
      "750S",
      "765LT",
      "ARTURA",
      "ELVA",
      "GT",
      "MP4-12C",
      "P1",
      "SENNA",
      "SENNA GTR",
    ],
    "Mercedes Benz": [
      "190",
      "240",
      "260",
      "280",
      "300",
      "350",
      "380",
      "400",
      "420",
      "500",
      "560",
      "600",
      "A-Class",
      "AMG GT",
      "B-Class",
      "C-Class",
      "CL-Class",
      "CLA-Class",
      "CLE",
      "CLK-Class",
      "CLS-Class",
      "E-Class",
      "EQB-Class",
      "EQC-Class",
      "EQE-Class Sedan",
      "EQE-Class SUV",
      "EQS-Class Sedan",
      "EQS-Class SUV",
      "eSprinter",
      "G-Class",
      "GL-Class",
      "GLA-Class",
      "GLB-Class",
      "GLC-Class",
      "GLE-Class",
      "GLK-Class",
      "GLS-Class",
      "L1013",
      "L1113",
      "L1116",
      "L1117",
      "L1316",
      "L1317",
      "L1319",
      "L1418",
      "L1419",
      "LP1219",
      "LP1419",
      "LPS1525",
      "M-Class",
      "Metris",
      "ML-Class",
      "R-Class",
      "S-Class",
      "SL-Class",
      "SLC-Class",
      "SLK",
      "SLR",
      "SLS-Class",
      "Sprinter",
    ],
    Mercury: [
      "Capri",
      "Cougar",
      "Grand Marquis",
      "Lynx",
      "Marauder",
      "Mariner",
      "MARQUIS",
      "Milan",
      "Montego",
      "Monterey",
      "Mountaineer",
      "Mystique",
      "Sable",
      "Topaz",
      "Tracer",
      "Villager",
      "Zephyr",
    ],
    Mini: [
      "Clubman",
      "Cooper",
      "Cooper Convertible",
      "Cooper Coupe",
      "Cooper Roadster",
      "Countryman",
      "Hardtop",
      "Paceman",
    ],
    Mitsubishi: [
      "3000GT",
      "Cordia",
      "Diamante",
      "Eclipse",
      "Eclipse Cross",
      "Endeavor",
      "Expo",
      "Galant",
      "i-MiEV",
      "Lancer",
      "Lancer Evolution",
      "Lancer SportBack",
      "Low Speed Vehicle",
      "Mighty Max",
      "Mirage",
      "Mirage G4",
      "Montero",
      "Montero Sport",
      "Outlander",
      "Outlander Sport",
      "Precis",
      "Raider",
      "RVR",
      "Sigma",
      "SP",
      "Space Wagon",
      "SPX",
      "Starion",
      "Tredia",
      "Truck",
      "Van",
      "Wagon",
    ],
    Nissan: [
      "200SX",
      "240SX",
      "300ZX",
      "350Z",
      "370Z",
      "Altima",
      "Altra",
      "Altra-EV",
      "ARIYA",
      "Armada",
      "Axxess",
      "Cube",
      "Frontier",
      "GT-R",
      "Juke",
      "Kicks",
      "Leaf",
      "Maxima",
      "Micra",
      "Murano",
      "NISSAN Z",
      "NV",
      "NV200",
      "NX",
      "Pathfinder",
      "Pickup",
      "PULSAR",
      "Quest",
      "Rogue",
      "Rogue Select",
      "Rogue Sport",
      "Sentra",
      "Sentra Classic",
      "Stanza",
      "Stanza Wagon",
      "Titan",
      "Van",
      "Versa",
      "Versa Note",
      "X-Trail",
      "Xterra",
    ],
    Oldsmobile: [
      "Achieva",
      "Alero",
      "Aurora",
      "Bravada",
      "Ciera",
      "Custom Cruiser",
      "Cutlass",
      "Cutlass Calais",
      "Cutlass Ciera",
      "Cutlass Cruiser",
      "Cutlass Salon",
      "Cutlass Supreme",
      "Delta 88",
      "Eighty Eight (88)",
      "Firenza",
      "Intrigue",
      "LSS",
      "Ninety Eight (98)",
      "Omega",
      "Regency",
      "Silhouette",
      "Toronado",
    ],
    Peugeot: ["405", "504", "505", "604"],
    Plymouth: [
      "Acclaim",
      "Arrow",
      "Breeze",
      "CARAVELLE",
      "Champ",
      "Colt",
      "CONQUEST",
      "GRAN FURY",
      "Grand Voyager",
      "HORIZON",
      "Laser",
      "Neon",
      "Prowler",
      "RELIANT",
      "SAPPORO",
      "Scamp",
      "Sundance",
      "Trailduster",
      "TURISMO",
      "Voyager",
    ],
    Pontiac: [
      "Acadian",
      "Aztek",
      "Bonneville",
      "Catalina",
      "Fiero",
      "Firebird",
      "Firefly",
      "G3",
      "G5",
      "G6",
      "G8",
      "Grand AM",
      "Grand Lemans",
      "Grand Prix",
      "GTO",
      "J2000",
      "Laurentian",
      "Lemans",
      "Matiz",
      "Montana",
      "Montana SV6",
      "Parisienne",
      "Phoenix",
      "Pursuit",
      "Safari",
      "Solstice",
      "Sunbird",
      "Sunbird 2000",
      "Sunburst",
      "Sunfire",
      "T1000",
      "Tempest",
      "Torrent",
      "Trans Sport",
      "Vibe",
      "Wave",
    ],
    Porsche: [
      "911",
      "918",
      "924",
      "928",
      "944",
      "968",
      "718 Boxster",
      "718 Cayman",
      "718 Spyder",
      "Boxster",
      "Cayenne",
      "Cayman",
      "Macan",
      "Panamera",
      "Taycan",
    ],
    RAM: [
      "Truck 1500 Series",
      "Truck 2500 Series",
      "Truck 3500 Series",
      "Truck 4000 Series",
      "4500",
      "Truck 5500 Series",
      "Cargo Van",
      "ProMaster 1500",
      "ProMaster 2500",
      "ProMaster 3500",
      "Promaster City",
    ],
    SAAB: ["900", "9000", "45538", "45540", "9-2X", "9-4x", "9-7X"],
    Saturn: [
      "Astra",
      "Aura",
      "Ion",
      "L200",
      "L300",
      "LS",
      "LS1",
      "LS2",
      "LW1",
      "LW2",
      "LW200",
      "LW300",
      "Outlook",
      "Relay",
      "SC1",
      "SC2",
      "Sky",
      "SL",
      "SL1",
      "SL2",
      "SL3",
      "SW1",
      "SW2",
      "Vue",
    ],
    Scion: [
      "Scion FR-S",
      "Scion iA",
      "Scion iM",
      "Scion iQ",
      "Scion tC",
      "Scion xA",
      "Scion xB",
      "Scion xD",
    ],
    Subaru: [
      "Ascent",
      "B9 Tribeca",
      "Baja",
      "Brat",
      "BRZ",
      "Crosstrek",
      "DL",
      "Forester",
      "GL",
      "GL-10",
      "GLF",
      "Impreza",
      "Justy",
      "Legacy",
      "Loyale",
      "Outback",
      "RX",
      "Solterra",
      "Standard",
      "SVX",
      "WRX",
      "XT",
      "XT6",
      "XV CrossTrek",
    ],
    Tesla: [
      "Cybertruck",
      "Model 3",
      "Model S",
      "Model X",
      "Model Y",
      "Roadster",
      "Semi",
    ],
    Toyota: [
      "86",
      "4-Runner",
      "Avalon",
      "bZ4X",
      "C-HR",
      "Camry",
      "Camry Solara",
      "Cargo Van",
      "Celica",
      "Corolla",
      "Corolla Cross",
      "COROLLA iM",
      "Corolla Matrix",
      "Corona",
      "Cressida",
      "Crown",
      "Echo",
      "FCHV-adv",
      "FJ Cruiser",
      "GR Corolla",
      "GR86",
      "Grand Highlander",
      "Highlander",
      "Land Cruiser",
      "Mirai",
      "MR2",
      "Paseo",
      "Pick-Up",
      "Previa",
      "Prius",
      "Prius C",
      "Prius Plug-in",
      "Prius Prime",
      "Prius V",
      "RAV4",
      "RAV4 Prime",
      "Sequoia",
      "Sienna",
      "Starlet",
      "Supra",
      "T100",
      "Tacoma",
      "Tercel",
      "Tundra",
      "Van",
      "Venza",
      "Yaris",
      "Yaris iA",
    ],
    Volkswagen: [
      "Arteon",
      "Atlas",
      "Atlas Cross Sport",
      "Beetle",
      "Cabrio",
      "CABRIOLET",
      "CC",
      "Corrado",
      "Dasher",
      "e-Golf",
      "Eos",
      "EuroVan",
      "FOX",
      "GLI",
      "Golf",
      "Golf Alltrack",
      "Golf GTI",
      "Golf III",
      "Golf R",
      "Golf SportWagen",
      "GTI",
      "ID.4",
      "Jetta",
      "Jetta GLI",
      "Jetta SportWagen",
      "Jetta Wagon",
      "KOMBI",
      "Passat",
      "Phaeton",
      "Quantum",
      "R32",
      "Rabbit",
      "Routan",
      "Scirocco",
      "Taos",
      "Tiguan",
      "Tiguan Limited",
      "Touareg",
      "Vanagon",
    ],
    Volvo: [
      "9700",
      "240 Series",
      "260 Series",
      "740 Series",
      "760 Series",
      "780 Series",
      "850 Series",
      "940 Series",
      "960 Series",
      "C30",
      "C40",
      "C70",
      "C70 / C30",
      "Cab Behind Engine",
      "Cab Over Engine HT",
      "Cab Over Engine LT",
      "F12 w/F7 Cab",
      "F6 w/F7 Cab",
      "S40",
      "S60",
      "S60 Cross Country",
      "S70",
      "S80",
      "S90",
      "V40",
      "V50",
      "V60",
      "V60CC",
      "V70",
      "V90",
      "V90CC",
      "VS",
      "VT",
      "XC40",
      "XC60",
      "XC70",
      "XC90",
    ],
  };