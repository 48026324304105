
import LeadForm from "./LeadFrom/LeadForm"
function App() {
  return (
    <div className="App">
    <LeadForm/>
    </div>
  );
}

export default App;
